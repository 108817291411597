<template>
    <van-tabbar v-model="active" :safe-area-inset-bottom="true">
        <!-- <van-tabbar-item name="/index" to="/index" icon="home-o">首页</van-tabbar-item> -->
        <!-- <van-tabbar-item name="/index" to="/index" icon="home-o">
            <span>首页</span>
            <template #icon="props">
                <img v-if="props.active" src="@/assets/img/icon_sy_xz@2x.png" alt="">
                <img v-else src="@/assets/img/icon_sy_mz@2x.png" alt="">
            </template>
        </van-tabbar-item> -->
        <van-tabbar-item @click="hanldeClick('/construction')" name="/construction" to="/construction" icon="search">
            <span>工地</span>
            <template #icon="props">
                <img v-if="props.active" src="@/assets/img/icon_gd_xz@2x.png" alt="">
                <img v-else src="@/assets/img/icon_gd_mz@2x.png" alt="">
            </template>
        </van-tabbar-item>
        <van-tabbar-item @click="hanldeClick('/msg')" name="/msg" to="/msg" icon="friends-o">
            <van-badge v-if="remind_num" :content="remind_num" max="99" />
            <span>消息</span>
            <template #icon="props">
                <img v-if="props.active" src="@/assets/img/icon_xx_xz@2x.png" alt="">
                <img v-else src="@/assets/img/icon_xx_mz@2x.png" alt="">
            </template>
        </van-tabbar-item>
        <van-tabbar-item @click="hanldeClick('/mine')" name="/mine" to="/mine" icon="setting-o">
            <span>我的</span>
            <template #icon="props">
                <img v-if="props.active" src="@/assets/img/icon_wd_xz@2x.png" alt="">
                <img v-else src="@/assets/img/icon_wd_mz@2x.png" alt="">
            </template>
        </van-tabbar-item>
    </van-tabbar>
</template>

<script>
export default {
    data() {
        return {
            active    : '',
            remind_num: this.$ls.get('remind_num')
        }
    },
    created() {
        this.active = this.$route.path
    },
    methods: {
        hanldeClick(path) {
            console.log(this.$router, path)
            if (path !== this.$route.path) {
                this.$router.push({ path }).catch(err => { console.log(err) })
            }
        }
    }
}
</script>

<style scoped lang="scss">
::v-deep .van-tabbar-item {
    position: relative;
}
::v-deep .van-badge {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10000;
    margin-left: 12px;
}
</style>
<style lang="scss">
.van-tabbar {
    z-index: 3000;
}
</style>
